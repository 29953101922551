@font-face {
  font-family: "UltraThin";
  src: url(./SF-Pro-Display-Ultralight.ttf) format("opentype");
}
@font-face {
  font-family: "Thin";
  src: url(./SF-Pro-Display-Thin.ttf) format("opentype");
}

@font-face {
  font-family: "Light";
  src: url(./SF-Pro-Display-Light.ttf) format("opentype");
}

@font-face {
  font-family: "Regular";
  src: url(./SF-Pro-Display-Regular.ttf) format("opentype");
}

@font-face {
  font-family: "Italic";
  src: url(./SF-Pro-Display-RegularItalic.ttf) format("opentype");
}

@font-face {
  font-family: "Medium";
  src: url(./SF-Pro-Display-Medium.ttf) format("opentype");
}

@font-face {
  font-family: "Bold";
  src: url(./SF-Pro-Display-Bold.ttf) format("opentype");
}
@font-face {
  font-family: "SemiBold";
  src: url(./SF-Pro-Display-Semibold.ttf) format("opentype");
}

@font-face {
  font-family: "Black";
  src: url(./SF-Pro-Display-Black.ttf) format("opentype");
}
@font-face {
  font-family: "Heavy";
  src: url(./SF-Pro-Display-Heavy.ttf) format("opentype");
}
