@media (max-width: 1240px) {
  .CareerCorpImg {
    max-height: 175px !important;
    object-fit: contain !important;
  }
  .Arrow {
    margin: 8px 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .ArrowRight {
    margin: 8px 0;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }
  .CareerSection {
    flex-direction: column !important;
    padding: 8% 0% !important;
    flex-wrap: wrap !important;
    gap: 16px !important;
  }
  .TitleBox {
    flex-direction: column !important;
    align-items: center !important;
    justify-items: center !important;
    gap: 40px !important;
  }
  .TitleLeft {
    text-align: center !important;
    font-size: 1rem !important;
  }
  .MainContainer {
    gap: 0px !important;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center !important;
    height: auto !important;
  }
  .Project {
    padding: 40px 8% !important;
  }
  .DetailSection {
    height: auto !important;
    padding: 10px 0px !important;
  }
  .DetailLAbel {
    flex-direction: column !important;
    width: auto !important;
  }
  .ProjectTitle {
    font-size: 1.75rem !important;
    align-self: center !important;
  }
  .Li {
    font-size: 14px !important;
    padding-top: 8px !important;
    text-align: justify;
  }
  .ApplicationBox {
    gap: 40px !important;
  }
  .ProjectTitle {
    align-items: center !important;
    text-align: center;
    font-size: 1.75rem !important;
  }
  .LabelName {
    font-size: 1.2rem !important;
  }
  .LblJob {
    font-size: 1rem !important;
  }
}
