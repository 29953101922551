@media (max-width: 882px) {
  .NavigationContainer {
    display: flex !important;
    height: 101px !important;
  }

  .MainNavContainer {
    justify-self: center !important;
  }
  .NavigationTitle {
    font-size: 16px !important;
  }
  .Navbox {
    display: none !important;
  }
  .MenuIcon {
    display: flex !important;
    position: relative !important;
    bottom: 0%;
    right: 0;
    z-index: 999;
  }
  .ModalContainer {
  }
}

@media (min-width: 883px) {
  .ModalContainer {
    display: none !important;
  }
}
