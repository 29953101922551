@media (max-width: 1240px) {
  .parent-container {
    display: flex;
    height: auto !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .Heading {
    width: auto !important;
    height: auto !important;
    flex-wrap: wrap !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 42px 0px;
    gap: 50px !important;
  }
  .BGCircle {
    display: none;
    height: 250px;
    width: 250px;
    position: absolute;
    margin-left: 0px;
    z-index: 0;
    top: 100px;
    float: right !important;
    right: 100px !important;
  }
  .MainContainer {
    height: 72vh;
    position: relative;
    display: flex;
    flex-direction: column !important;
    justify-content: space-between !important;
  }
  .GreetingBox {
    transform: rotate(0deg) !important;
    width: auto !important;
  }
  .BgRight {
    display: none;
  }
  .Name {
    font-size: 48px !important;
  }
  .HeadingName {
    font-size: 32px !important;
    width: 262px !important;
  }
  .myphoto {
    height: auto !important;
    width: 320px !important;
    object-fit: contain;
    z-index: 100 !important;
  }
  /* Skills Sections Mobile */

  .skill-container {
    padding: 42px 0px !important;
  }
  .skill-main {
    width: 100%;
    display: flex;
    flex-direction: row !important;
    justify-content: center !important;
    align-items: center;
  }
  .headingname {
    align-self: center !important;
  }

  /* Education Section Mobile */
  .EducationSection {
    margin-top: 24px;
    height: auto !important;
    flex-direction: column !important;
    flex-wrap: nowrap;
    align-self: center !important;
    justify-content: center !important;
  }

  .SectionEducDetail {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: center !important;
    align-self: flex-start !important;
    margin: 16px 0px !important;
    gap: 0px !important;
  }
  .DiplomaHolder {
    font-size: 28px !important;
    text-transform: uppercase;
    margin: 0px !important;
  }
  .CollgeName {
    font-size: 18px !important;
    margin-top: 0px;
    margin: 0px;
  }
  .CourseName {
    font-size: 16px !important;
  }
  .MyPhotos {
    height: auto !important;
    width: 300px !important;
    margin-left: 0px !important;
    margin: 0px !important;
  }
  /* Proff Section Mobile */
  .ProfSection {
    height: auto !important;
    justify-content: center !important;
    align-content: center !important;
    padding: 50px 0px !important;
  }

  .TrainingBOx {
    display: flex;
    margin-top: 16px;
    flex: 0 !important;
    gap: 16px !important;
    flex-direction: row;
    margin-left: 0px !important;
    flex-wrap: wrap;
  }
  .TrainingList {
    width: 250px !important;
    flex-direction: column;
  }
  /* Attainment Responsive */
  .SpaceSection {
    height: auto !important;
    flex-direction: column !important;
  }
  .row {
    display: flex;
    flex-direction: column !important;
  }
  .attain {
    align-self: center !important;
    font-size: 56px !important;
    padding: 10px 20px !important;
    text-align: center !important;
    transform: rotate(0deg) !important;
  }
  .qoute {
    text-align: center !important;
    font-size: 16px !important;
  }
  .footer {
    display: flex !important;
    height: auto !important;
  }
  .FooterMain {
    width: 100% !important;
    height: auto !important;
    flex-direction: column !important;
  }
  .LinkWrap {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 15px;
    padding: 10px !important;
  }
}
