@media (max-width: 1240px) {
  .maincontainer {
    flex-direction: column !important;
    gap: 10px !important;
  }
  .arrow {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-start;
    padding-left: 37px;
    gap: 10px;
  }
  .arrowr {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    align-self: flex-end !important;
    padding-right: 37px;
    gap: 10px;
  }
  .chevronl {
    height: 20px !important;
    width: 20px !important;
  }
  .Display {
    gap: 40px !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .MobileLeftSection {
    align-items: center !important;
  }
  .MobileRightSection {
    gap: 10px !important;
  }
  .ULList {
    padding: 0 42px !important;
    align-items: center !important;
    flex-wrap: wrap !important;
    width: auto !important;
  }
  .MobileDisplay {
    height: 440px !important;
    width: 220px !important;
    object-fit: cover !important;
  }

  /* Display */
  .TitleName {
    font-size: 32px !important;
    padding: 0 40px !important;
    margin: 0px !important;
    margin-top: -15px !important;
    align-self: flex-start !important;
  }
  .TypeName {
    margin: 10px 0 !important;
    padding: 0 40px !important;
    align-self: flex-start !important;
  }
  .Description {
    width: 320px !important;
    height: auto !important;
    padding: 0px 42px !important;
    margin-top: 0px !important;
    line-height: 24px !important;
  }
  .ArrowSection {
    display: flex !important;
  }
  .ImageList {
    display: none !important;
    width: 350px !important;
    padding: 0 40px !important;
  }

  /* Website */
  .WebDisplay {
    width: 110% !important;
    object-fit: cover !important;
  }
  .WebSection {
    flex: 1;
    width: 322px !important;
    height: auto !important;
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: center !important;
    align-items: center;
    gap: 0px;
  }
  .webleft {
    align-items: center !important;
  }
  .DesSection {
    flex-wrap: wrap !important;
    padding-left: 0px !important;
  }
  .ImageListWeb {
    height: 250px !important;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    border-radius: 4px;
    display: none !important;
  }
  .DescriptionWeb {
    text-align: justify !important;
    word-break: break-word !important;
    padding: 0 40px !important;
  }
  .List {
    font-size: 14px !important;
  }
}
