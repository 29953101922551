@media (max-width: 550px) {
  .navigation-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    padding: 0 10px !important;
    width: 95%;
  }
  .header-logo {
    font-size: 16px !important;
  }
  .nav-link {
    font-size: 14px !important;
  }

  .container {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
  }
  .boxcontainer {
    height: auto !important;
    width: auto !important;
    padding: 0 50px;
  }
  .header {
    width: 98% !important;
    height: auto;
    display: flex !important;
    flex-direction: column !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 16px !important ;
  }
  .header-txt {
    font-size: 2.5rem !important;
    text-align: center;
    padding: 0;
  }
  .header-hero {
    height: 250px !important;
    width: 100% !important;
  }
  .header-p {
    font-size: 12px !important;
    text-align: center;
  }
  .section {
    margin-top: 0px !important;
    padding: 10px 0px !important;
    margin-bottom: 40px;
  }
  .section-h1 {
    font-size: 1.5rem !important;
    text-align: center;
    margin: 16px 0;
  }
  .box1 {
    height: auto !important;
    width: auto !important;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
    padding: 0 24px !important;
  }
  .headerP {
    font-size: 1rem !important;
    text-align: center;
  }
  .btm-header {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center !important;
    padding: 1% 0 !important;
    gap: 4px !important;
  }
  .card-container {
    gap: 15px 10px !important;
  }
  .header-image {
    padding: 5% 0 !important;
    object-fit: fill !important;
    object-position: top !important;
  }
  .career-text {
    font-size: 2.5rem !important;
    text-align: justify;
    padding: 3%;
  }
  .port-image {
    padding: 1% 0 !important;
    object-fit: cover !important;
    height: max-content;
    object-position: top !important;
  }
  .article {
    padding: 31px 14px !important;
  }
  .img-display {
    width: 97% !important;
    height: 250px !important;
    object-fit: cover;
    object-position: top;
  }
  .card-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    background-color: #363636;
    margin: 8px;
    width: 100%;
    max-width: 380px !important;
    overflow: auto;
  }

  /* BLogs */
  .contentContainer {
    width: 350px !important;
  }
  .header {
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    text-align: left !important;
    padding: 8px 23px !important;
  }
  .title {
    font-size: 1.2rem !important ;
    width: 300px;
  }
  .imageDisplay {
    width: 310px !important;
  }
}
