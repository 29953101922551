@media (max-width: 1240px) {
  .box {
    width: auto !important;
    border: 8px solid white !important;
    border-radius: 8px;
    transform: rotate(0deg) !important;
    margin: 0px !important;
    padding: 20px 0px !important;
  }
  .title {
    margin: 0px;
    font-size: 42px !important;
    position: relative;
    transform: rotate(1deg) !important;
    padding: 0px !important;
    text-align: center;
  }
}
